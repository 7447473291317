import {
  RiBarChart2Fill,
  RiBarcodeLine,
  RiLifebuoyLine,
  RiNotification3Fill,
  RiSettings5Fill,
  RiWechatFill,
  RiShoppingCart2Line,
  RiUser3Line,
  RiArrowRightLine,
  RiRewindFill,
  RiSpeedFill,
  RiPlayFill,
  RiPauseFill,
  RiLogoutBoxRLine,
  RiAdminLine,
  RiMap2Fill,
  RiEyeFill,
  RiUpload2Line,
  RiShoppingCart2Fill,
  RiBarChartFill,
  RiArrowLeftRightFill,
  RiTeamLine,
  RiAlertFill,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiCupFill,
  RiCalendarCheckFill,
  RiAddBoxFill,
  RiRadarFill,
  RiMailSendFill,
  RiArrowDownCircleFill,
} from 'react-icons/ri';
import { MdTrendingUp, MdThumbUp, MdThumbDown, MdOutlineQuestionMark } from 'react-icons/md';
import { FaCashRegister, FaBrain, FaTicketAlt, FaPrint, FaTimes, FaCheck, FaMinus } from 'react-icons/fa';
import { SiTarget } from 'react-icons/si';
import { BsWindowFullscreen, BsWindowSidebar } from 'react-icons/bs';
import { TbDiscount } from 'react-icons/tb';
import { IoStatsChart, IoStatsChartOutline } from 'react-icons/io5';

import { wrapIcon } from './wrapIcon';

export const ObjectivesIcon = wrapIcon(SiTarget);
export const OrdersIcon = wrapIcon(RiArrowDownCircleFill);
export const OperationsIcon = wrapIcon(RiBarChart2Fill);
export const AnalyticsIcon = wrapIcon(RiBarcodeLine);
export const SettingsIcon = wrapIcon(RiSettings5Fill);
export const NeoEmailIcon = wrapIcon(RiMailSendFill);
export const HelpIcon = wrapIcon(RiLifebuoyLine);
export const ChatIcon = wrapIcon(RiWechatFill);
export const NotificationsIcon = wrapIcon(RiNotification3Fill);
export const ProductsIcon = AnalyticsIcon;
export const SalesIcon = wrapIcon(MdTrendingUp);
export const ThumbUpIcon = wrapIcon(MdThumbUp);
export const ThumbDownIcon = wrapIcon(MdThumbDown);
export const QuestionMarkIcon = wrapIcon(MdOutlineQuestionMark);
export const TimesIcon = wrapIcon(FaTimes);
export const CheckIcon = wrapIcon(FaCheck);
export const CartIcon = wrapIcon(RiShoppingCart2Line);
export const CartFillIcon = wrapIcon(RiShoppingCart2Fill);
export const CustomersIcon = wrapIcon(RiUser3Line);
export const RolesIcon = wrapIcon(RiAdminLine);
export const RegistersIcon = wrapIcon(FaCashRegister);
export const InsightsIcon = wrapIcon(FaBrain);
export const PrintIcon = wrapIcon(FaPrint);
export const MinusIcon = wrapIcon(FaMinus);
export const TicketIcon = wrapIcon(FaTicketAlt);
export const KPIArrowIcon = wrapIcon(RiArrowRightLine);
export const RewindIcon = wrapIcon(RiRewindFill);
export const ForwardIcon = wrapIcon(RiSpeedFill);
export const PlayIcon = wrapIcon(RiPlayFill);
export const PauseIcon = wrapIcon(RiPauseFill);
export const LogoutIcon = wrapIcon(RiLogoutBoxRLine);
export const MapIcon = wrapIcon(RiMap2Fill);
export const UploadIcon = wrapIcon(RiUpload2Line);
export const PerformanceIcon = wrapIcon(RiBarChartFill);
export const SalesWatchlistIcon = wrapIcon(RiEyeFill);
export const ProcessingIcon = wrapIcon(RiArrowLeftRightFill);
export const ArrowUp = wrapIcon(RiArrowUpSLine);
export const ArrowDown = wrapIcon(RiArrowDownSLine);
export const UsersIcon = wrapIcon(RiTeamLine);
export const AlertIcon = wrapIcon(RiAlertFill);
export const MorningIcon = wrapIcon(RiCupFill);
export const DailyIcon = wrapIcon(RiCalendarCheckFill);
export const FreeTimeIcon = wrapIcon(RiAddBoxFill);
export const AreaIcon = wrapIcon(RiRadarFill);
export const WindowFullscreen = wrapIcon(BsWindowFullscreen);
export const WindowSidebar = wrapIcon(BsWindowSidebar);
export const DiscountIcon = wrapIcon(TbDiscount);
export const StatsIconOn = wrapIcon(IoStatsChart);
export const StatsIconOff = wrapIcon(IoStatsChartOutline);
