import { ProductCodeValidator, TFamilyCode, TSectionCode } from 'domain/core';
import { ColumnSorts } from 'domain/stocks';
import { StoreIdValidator, TStoreId } from 'domain/stores';
import * as t from 'io-ts';
import { optionFromNullable } from 'io-ts-types';

export type TSalesQty = t.TypeOf<typeof SalesQtyValidator>;

export type OrdersParams = {
  store_ids?: TStoreId[];
  section_ids?: TFamilyCode[];
};

export type SalesPredictionParams = {
  date?: string;
  page?: number;
  size?: number;
  ean?: string;
  name?: string;
  column_sort?: ColumnSorts | 'ordered';
  delivery_type?: 'AB' | 'AC' | 'AD';
  order?: 'ASC' | 'DESC';
  shelf?: string;
  assortment_filter?: boolean;
} & OrdersParams;

export type CashierPredictionParams = {
  store_ids?: TStoreId;
  weeks?: number;
  date?: string;
};

export type SectionPredictionParams = {
  date?: string;
  store_id?: TStoreId;
  section_id?: TSectionCode;
};

const MinMaxValidator = t.type({
  min: t.number,
  max: t.number,
});

const SalesQtyValidator = t.type({
  prediction: t.number,
  yesterday: t.number,
  quantity: t.number,
  prediction_rounded: t.number,
  delivered: t.number,
  real_stock: t.number,
  wasted_stolen: t.number,
  from_7_days_ago: t.number,
  from_14_days_ago: t.number,
  last_30_days: MinMaxValidator,
  last_90_days: MinMaxValidator,
});

const ShortagesCountValidator = t.type({
  last_30_days: t.number,
  last_90_days: t.number,
});

export const SalesPredictionValidator = t.type({
  always_available: optionFromNullable(t.boolean),
  product_code: ProductCodeValidator,
  description: t.string,
  ifls: optionFromNullable(t.string),
  ean13: optionFromNullable(t.number),
  order_description: optionFromNullable(t.string),
  package_size: optionFromNullable(t.number),
  active_promo: optionFromNullable(t.boolean),
  shelf: optionFromNullable(t.string),
  sales_qty: SalesQtyValidator,
  shortages_count: ShortagesCountValidator,
  stock_problems: optionFromNullable(t.boolean),
  product_latest_price: optionFromNullable(t.number),
});

const OrderDataValidator = t.type({
  product_code: t.number, //NOTE: it's change to simplify generating from record
  store_id: StoreIdValidator,
  quantity: t.number,
  delivered: t.number,
  date: t.string,
});

export type OrderData = t.TypeOf<typeof OrderDataValidator>;

const OrderDataListValidator = t.array(OrderDataValidator);

export type OrderDataList = t.TypeOf<typeof OrderDataListValidator>;

const GroupedOrder = t.type({
  ean13: t.number,
  product_code: t.number, //NOTE: it's change to simplify generating from record
  quantity: t.number,
  store_name: t.string,
  description: t.string,
  delivered: optionFromNullable(t.number),
  date: t.string,
});

const GroupedOrdersListValidator = t.array(GroupedOrder);

export type TGroupedOrder = t.TypeOf<typeof GroupedOrder>;

export type TGroupedOrdersList = t.TypeOf<typeof GroupedOrdersListValidator>;

export const GroupedOrdersValidator = t.record(t.string, GroupedOrdersListValidator);

export type TGroupedOrders = t.TypeOf<typeof GroupedOrdersValidator>;

export type TSalesPrediction = t.TypeOf<typeof SalesPredictionValidator>;

export const SalesPredictionListValidator = t.array(SalesPredictionValidator);

export type TSalesPredictionList = t.TypeOf<typeof SalesPredictionListValidator>;

export const isSalesPredictionList = (
  prediction: TSalesPredictionList | undefined,
): prediction is TSalesPredictionList => {
  return prediction !== undefined;
};

export const checkAllPredictions = (
  predictions: (TSalesPredictionList | undefined)[],
): predictions is TSalesPredictionList[] => {
  return predictions.every(isSalesPredictionList);
};

export type HistoricCustomersCount = t.TypeOf<typeof HistoricSectionRecord>;

export type TCustomersPrediction = t.TypeOf<typeof CustomersPredictionValidator>;

type THistoricCustomersCount = t.TypeOf<typeof HistoricSectionRecord>;

type TPredictionCustomersCount = t.TypeOf<typeof PredictionDateQuantiles>;

type THistoricPredictionCustomersCount = t.TypeOf<typeof HistoricPredictionQuantilesRecord>;

export type HistoricGrossIncome = t.TypeOf<typeof HistoricSectionRecord>;

export type TGrossIncomePrediction = t.TypeOf<typeof GrossIncomePredictionValidator>;

type THistoricGrossIncome = t.TypeOf<typeof HistoricSectionRecord>;

type THistoricPredictionGrossIncome = t.TypeOf<typeof HistoricPredictionQuantilesRecord>;

type TGrossIncome = t.TypeOf<typeof PredictionDateQuantiles>;

export type TStoreBudgets = t.TypeOf<typeof PredictionStoreBudgetsArray>;

export type CustomerPrediction = {
  historic_customers_count?: THistoricCustomersCount;
  historic_prediction_customers_count?: THistoricPredictionCustomersCount;
  prediction_customers_count?: TPredictionCustomersCount;
};

export type GrossIncomePrediction = {
  historic_gross_income?: THistoricGrossIncome;
  historic_prediction_gross_income?: THistoricPredictionGrossIncome;
  prediction_gross_income?: TGrossIncome;
  store_budgets?: TStoreBudgets;
};

export const SalesPredictionPageValidator = SalesPredictionListValidator;

const ShiftRangeValidator = t.type({
  start: t.string,
  end: t.string,
});

const CashiersQtyValidator = t.type({
  risky: t.number,
  safe: t.number,
});
export const CashierPredictionValidator = t.type({
  store_id: StoreIdValidator,
  shift: ShiftRangeValidator,
  cashiers_quantity: CashiersQtyValidator,
});

export const CashierPredictionListValidator = t.array(CashierPredictionValidator);

const HistoricSectionRecord = t.record(t.string, t.number);

const PredictionDateQuantiles = t.type({
  date: t.string,
  quantile_5: t.number,
  quantile_25: t.number,
  quantile_50: t.number,
  quantile_75: t.number,
  quantile_95: t.number,
});

const HistoricPredictionQuantiles = t.type({
  quantile_5: t.number,
  quantile_25: t.number,
  quantile_50: t.number,
  quantile_75: t.number,
  quantile_95: t.number,
});

export type TPredictionStoreBudget = {
  date: string;
  expected_budget: number;
};

const PredictionStoreBudget = t.type({
  date: t.string,
  expected_budget: t.number,
});

const PredictionStoreBudgetsArray = t.array(PredictionStoreBudget);

const HistoricPredictionDate = t.string;
const HistoricPredictionQuantilesRecord = t.record(
  HistoricPredictionDate,
  optionFromNullable(HistoricPredictionQuantiles),
);

export const CustomersPredictionValidator = t.type({
  historic_customers_count: HistoricSectionRecord,
  historic_prediction_customers_count: HistoricPredictionQuantilesRecord,
  prediction_customers_count: optionFromNullable(PredictionDateQuantiles),
});

export const GrossIncomePredictionValidator = t.type({
  historic_gross_income: HistoricSectionRecord,
  historic_prediction_gross_income: HistoricPredictionQuantilesRecord,
  prediction_gross_income: optionFromNullable(PredictionDateQuantiles),
  store_budgets: PredictionStoreBudgetsArray,
});

export const BudgetInfoValidator = t.type({
  date: t.string,
  expected_budget: t.number,
});

export const BudgetInfoListValidator = t.array(BudgetInfoValidator);
