import React, { VFC } from 'react';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { map } from 'fp-ts/Array';
import {
  createStockInsightsMatcher,
  foldStockInsights,
  TExceptionalSales,
  TLastTimeSold,
  TSalesAboveAverage,
  TStockInsights,
} from 'domain/stocks';
import { Center, Stack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

export const StockInsights: VFC<{ insights: TStockInsights }> = ({ insights }) => {
  const { t } = useTranslation('whisperme');

  return (
    <Stack spacing={2} justifyItems="center" alignItems="center">
      {pipe(
        insights,
        O.match(
          () => <Center>{t('MORNING_TAKS.NO_DATA_LABEL')}</Center>,
          (values) => (
            <>
              {pipe(
                values,
                map(
                  foldStockInsights(
                    () => <></>,
                    createStockInsightsMatcher('SALES_ABOVE_AVERAGE', (v: TSalesAboveAverage['insight_details']) => (
                      <Text>
                        {t('MORNING_TAKS.SALES_ABOVE_AVERAGE_LABEL')}: {(v.gross / 100).toFixed(2)} EUR /{' '}
                        {(v.avg_gross_last_30_days / 100).toFixed(2)} EUR
                      </Text>
                    )),
                    createStockInsightsMatcher('LAST_TIME_SOLD', (v: TLastTimeSold['insight_details']) => (
                      <Text>
                        {t('MORNING_TAKS.LAST_TIME_SOLD_LABEL')}{' '}
                        {format(new Date(v.last_time_sold), 'HH:MM yyyy.MM.dd')}
                      </Text>
                    )),
                    createStockInsightsMatcher('EXCEPTIONAL_SALE', (v: TExceptionalSales['insight_details']) => (
                      <Text>
                        {t('MORNING_TAKS.EXCEPTIONAL_SALE_LABEL')} {v.quantity}, avg. {v.avg_quantity.toFixed(2)}
                      </Text>
                    )),
                  ),
                ),
              )}
            </>
          ),
        ),
      )}
    </Stack>
  );
};
