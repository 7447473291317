import { TFamilyCode } from 'domain/core';
import { TStoreId, StoreIdValidator } from 'domain/stores';
import * as t from 'io-ts';
import { optionFromNullable } from 'io-ts-types';

const optionalNumber = optionFromNullable(t.number);

export const HistorialProductStockValidator = t.type({
  start_of_day: optionalNumber,
  delivered: optionalNumber,
  sold_returned: optionalNumber,
  wasted: optionalNumber,
  stolen: optionalNumber,
  end_of_day: optionalNumber,
  quantity_regular: optionalNumber,
  quantity_discount: optionalNumber,
});

export type THistorialProductStock = t.TypeOf<typeof HistorialProductStockValidator>;

export const HistorialProductStockCalendarValidator = t.record(t.string, HistorialProductStockValidator);

export type THistorialProductStockCalendar = t.TypeOf<typeof HistorialProductStockCalendarValidator>;

const LastTimeSoldValidator = t.type({
  insight_type: t.literal('LAST_TIME_SOLD'),
  insight_details: t.type({
    last_time_sold: t.string,
  }),
});

export type TLastTimeSold = t.TypeOf<typeof LastTimeSoldValidator>;

const ExceptionalSalesValidator = t.type({
  insight_type: t.literal('EXCEPTIONAL_SALE'),
  insight_details: t.type({
    receipt_id: t.string,
    product_code: t.number,
    quantity: t.number,
    avg_quantity: t.number,
    stddev_quantity: t.number,
  }),
});

export type TExceptionalSales = t.TypeOf<typeof ExceptionalSalesValidator>;

const SalesAboveAverageValidator = t.type({
  insight_type: t.literal('SALES_ABOVE_AVERAGE'),
  insight_details: t.type({
    gross: t.number,
    avg_gross_last_30_days: t.number,
  }),
});

export type TSalesAboveAverage = t.TypeOf<typeof SalesAboveAverageValidator>;

export const StockInsightValiator = t.union([
  LastTimeSoldValidator,
  ExceptionalSalesValidator,
  SalesAboveAverageValidator,
]);

export type TStockInsight = t.TypeOf<typeof StockInsightValiator>;

const StockInsightsValidator = optionFromNullable(t.array(StockInsightValiator));

export type TStockInsights = t.TypeOf<typeof StockInsightsValidator>;

export const StockDataValidator = t.intersection([
  t.type({
    product_code: t.number,
    ifls: optionFromNullable(t.string),
    ean13: optionFromNullable(t.number),
    order_description: optionFromNullable(t.string),
    shelf: optionFromNullable(t.string),
    description: t.string,
    always_available: optionFromNullable(t.boolean),
    position_in_store: optionFromNullable(t.string),
    package_size: optionFromNullable(t.number),
    stock_problems: optionFromNullable(t.boolean),
    is_promo: optionFromNullable(t.boolean),
    store_id: StoreIdValidator,
    date: t.string,
    insights: StockInsightsValidator,
  }),
  HistorialProductStockValidator,
]);

export const StockPointValidator = t.type({
  product_code: t.number,
  store_id: t.string,
  time: t.string,
  quantity: t.number,
});

export const ProductShortageValidator = t.type({
  store_id: StoreIdValidator,
  time: optionFromNullable(t.string),
  always_available: optionFromNullable(t.boolean),
  shortage_days: optionFromNullable(t.number),
  shortage_hours: optionFromNullable(t.number),
  sales: optionFromNullable(t.number),
});

export const ShortageProductPointsValidator = t.type({
  store_id: t.string,
  time: t.string,
  always_available: t.boolean,
  shortage_count: t.number,
  new_ifls: t.array(t.string),
  reoccurring_ifls: t.array(t.string),
  lost_ifls: t.array(t.string),
});

export const AssortmentAnalysisValidator = t.type({
  product_code: t.number,
  ifls: optionFromNullable(t.string),
  order_description: optionFromNullable(t.string),
  bli_data: optionFromNullable(t.boolean),
  mercuriel_data: optionFromNullable(t.boolean),
  stock_data: optionFromNullable(t.boolean),
  is_promo: optionFromNullable(t.boolean),
});

export const ShortageProductValidator = t.type({
  ifls: optionFromNullable(t.string),
  product_code: t.number,
  shelf: optionFromNullable(t.string),
  description: t.string,
  always_available: optionFromNullable(t.boolean),
  shortage_weeks: optionFromNullable(t.number),
  shortage_days: optionFromNullable(t.number),
  shortage_hours: optionFromNullable(t.number),
  sales: optionFromNullable(t.number),
  is_promo: optionFromNullable(t.boolean),
  last_order_date: optionFromNullable(t.string),
});

export const IndividualShortagesPointValidator = t.type({
  always_available: optionFromNullable(t.boolean),
  availability: optionFromNullable(t.boolean),
  can_be_ordered: optionFromNullable(t.boolean),
  enumeration: optionFromNullable(t.number),
  product_code: t.number,
  ifls: t.string,
  shelf: optionFromNullable(t.string),
  store_id: optionFromNullable(t.string),
  time: t.string,
});

export type TIndividualShortagesPoint = t.TypeOf<typeof IndividualShortagesPointValidator>;

// TODO(d.malysa): remove this when API is ready
export type TStockAPIData = t.TypeOf<typeof StockDataValidator>;

export type TStockData = TStockAPIData;

export const StocksDataListValidator = t.array(StockDataValidator);

export const ShortageProductsListValidator = t.array(ShortageProductValidator);

export const ShortageProductPointsListValidator = t.array(ShortageProductPointsValidator);

export const IndividualShortagesPointsValidator = t.array(IndividualShortagesPointValidator);

export type TIndividualShortagesPoints = t.TypeOf<typeof IndividualShortagesPointsValidator>;

export const AssortmentAnalysisListValidator = t.array(AssortmentAnalysisValidator);

export type TStocksAPIData = t.TypeOf<typeof StocksDataListValidator>;

export type TStocksData = TStockData[];

export const StockPointsListValidator = t.array(StockPointValidator);

export type TStockPointsList = t.TypeOf<typeof StockPointsListValidator>;

export type ColumnSorts =
  | 'ean'
  | 'name'
  | 'position'
  | 'stock'
  | 'ifls'
  | 'ean13'
  | 'order_description'
  | 'subcategory'
  | 'shelf';

export type ShortagesColumnSorts = 'ean' | 'shortage_time' | 'shelf';

export type ColumnSortDirection = 'ASC' | 'DESC';

export type Commons = {
  store_id: TStoreId;
};

type DateRange = { start_date: Date; end_date: Date };

export type GetStocksForStore = {
  family_code?: TFamilyCode;
  root_family_code?: TFamilyCode;
  is_always_available?: boolean;
  page?: number;
  size?: number;
  name?: string;
  ean?: string;
  column_sort?: ColumnSorts;
  order?: ColumnSortDirection;
  delivery_day_filter?: boolean;
  assortment_filter?: boolean;
} & Commons;

export type ProductStockSaveData = [
  wasted: number | undefined,
  stolen: number | undefined,
  start_of_day: number | undefined,
];

export type SaveStocksForStore = {
  data: Record<string, ProductStockSaveData>;
} & Commons;

type HistoryParams = {
  product_code: string;
} & Commons;

export type GetStockProductHistory = HistoryParams & DateRange;

export type DeleteStocksForProduct = HistoryParams;

export type GetStockExportLink = DateRange & GetStocksForStore;

export type StocksPointsParams = {
  product_code: string;
  start_date: string;
  end_date: string;
} & Commons;

export type ShortageProductPointsParams = {
  start_date: string;
  end_date: string;
  always_available?: boolean;
} & Commons;

export type IndividualShortagesChartPointsParams = {
  start_date: string;
  end_date: string;
  shelf: string;
  //   max_products?: number;
} & Commons;

export type ShortageProductsListParams = {
  start_date: string;
  end_date: string;
  always_available?: boolean;
  column_sort?: ShortagesColumnSorts | 'ean';
  order?: 'ASC' | 'DESC';
  is_promo?: boolean;
} & Commons;
