import { createStockDataKey } from 'constants/queryCacheKeys';
import { GetStocksForStore, getStocksForStore } from 'domain/stocks';
import { useQuery } from 'react-query';

export type StocksStoreDataParams = {
  store: GetStocksForStore['store_id'];
  section?: GetStocksForStore['root_family_code'];
  is_always_available?: GetStocksForStore['is_always_available'];
  column_sort?: GetStocksForStore['column_sort'];
  order?: GetStocksForStore['order'];
  nameOrEan?: string;
  deliveryDay?: boolean;
};

export const useStocksStoreData = ({
  store,
  section,
  is_always_available,
  column_sort,
  order,
  nameOrEan,
  deliveryDay,
}: StocksStoreDataParams) => {
  const params: GetStocksForStore = {
    store_id: store,
    root_family_code: section,
    column_sort,
    order,
    size: 1_000,
    assortment_filter: true,
  };
  if (nameOrEan) {
    params[nameOrEan.match(/^\d+$/gi) ? 'ean' : 'name'] = nameOrEan;
  }
  if (deliveryDay) {
    params['delivery_day_filter'] = deliveryDay;
  }
  if (is_always_available) {
    params['is_always_available'] = is_always_available;
  }

  return useQuery(createStockDataKey(params), () => getStocksForStore(params));
};
