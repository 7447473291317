import React, { Suspense } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { DesktopSidebar } from 'components/Navigation';
import { CSS_VARIABLES } from 'constants/css';
import { useAppConfig } from 'hooks';
import { DashboardTopbar } from './DashboardTopbar';
import { contentPadding } from './styles';
import { PageSpinner } from 'components/Spinners';
import { ErrorBoundary } from 'components/ErrorMessages';

const {
  TOPBAR: { HEIGHT },
} = CSS_VARIABLES;

export const DashboardLayout: React.FC = ({ children }) => {
  const { viewport } = useAppConfig();

  return (
    <Flex minHeight="100%">
      {viewport === 'desktop' && <DesktopSidebar />}
      <Flex direction="column" width="100%" minWidth="0">
        <DashboardTopbar
          variant={viewport}
          sx={{
            ...contentPadding,
            bg: 'white',
            height: `var(${HEIGHT}, 72px)`,
            width: '100%',
            zIndex: 'docked',
            position: 'sticky',
            top: 0,
            boxShadow: 'md',
          }}
        />
        <Box as="main" {...contentPadding} width="100%" bg="gray.100" flexGrow={1}>
          <ErrorBoundary>
            <Suspense fallback={<PageSpinner />}>{children}</Suspense>
          </ErrorBoundary>
        </Box>
      </Flex>
    </Flex>
  );
};
