import React, { VFC } from 'react';
import { Grid, GridItem, GridItemProps, GridProps } from '@chakra-ui/react';

type GridTableHeaderProps = {
  rowProps?: GridProps;
  itemProps?: GridItemProps;
  items: string[];
};

export const GridTableHeader: VFC<GridTableHeaderProps> = ({ rowProps = {}, itemProps = {}, items }) => {
  return (
    <Grid {...rowProps}>
      {items.map((item, index) => (
        <GridItem fontSize="sm" {...itemProps} key={`${item}_${index}`}>
          {item}
        </GridItem>
      ))}
    </Grid>
  );
};
