/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useMemo, useState } from 'react';
import { Box, Checkbox, Grid, GridItem, HStack, Skeleton, Stack } from '@chakra-ui/react';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import { useFilterParams } from './hooks/useFilterParams';
import { useTranslation } from 'react-i18next';
import { useUserStoreIds } from 'hooks';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import {
  TWeeklyProductManagementParams,
  TWeeklyProductManagementList,
  TOStoreManagementValue,
  localTStoreManagementValue,
} from 'domain/products';
import { CardErrorBoundary } from 'components/ErrorMessages';
import { useQueryClient } from 'react-query';
import { TStoreId } from 'domain/stores';
import * as PATHS from 'constants/paths';
import { useWeeklyProductManagement } from './hooks/data';
import { Filters, Footer, ProductManagementColumn } from './components';
import { getOrEmptyString, getOrZero } from 'pages/SettingsPage/utils';
import { EditStoreContext } from './hooks/useEditStore';
import { TStoresToUpdate } from 'domain/products';
import { selectableTypes } from './components/types';
import { useUserContext } from 'hooks';
import { hasMonitorAccess, hasSectionManagerAccess } from 'domain/user';
import { SummaryStatistics } from './components/SummaryStatistics';

const daysLetters = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const JUMP = 10;
const PAGE_SIZE = 36;

const ASSERT_STATES: Record<localTStoreManagementValue, (v: TOStoreManagementValue) => boolean> = {
  nodata: (value) => !O.toUndefined(value),
  standard: (value) => O.toUndefined(value) === 'standard',
  promo: (value) => O.toUndefined(value) === 'promo',
  disabled: (value) => O.toUndefined(value) === 'disabled',
};

const isTop = (scrollTop: number, spacing = 0): boolean => {
  return scrollTop <= 0 + spacing;
};

const isBottom = (clientHeight: number, scrollHeight: number, scrollTop: number, spacing = 0) => {
  return scrollTop + clientHeight >= scrollHeight - spacing;
};

export const WeeklyProductListManagement: React.FC = () => {
  const { t } = useTranslation('whisperme');
  const userStoreIds = useUserStoreIds();
  const [summaryVisible, toggleSummaryStatistics] = useState(true);
  const { user } = useUserContext();
  const queryClient = useQueryClient();
  const monitorScope = hasMonitorAccess(user);
  const sectionMangerScope = hasSectionManagerAccess(user);
  const { shelf, week, onChangeShelf, onChangeWeek, types, onChangeTypes } = useFilterParams();
  const currentYear = new Date().getFullYear();

  const params: TWeeklyProductManagementParams = useMemo(() => {
    return {
      year: currentYear,
      week,
      sort_by: 'ifls',
      store_ids: monitorScope ? ['ALL'] : userStoreIds,
      limit: 1000,
    };
  }, [currentYear, week, monitorScope, userStoreIds]);

  const filteredSelectableTypes = useMemo(() => {
    if (sectionMangerScope) {
      return selectableTypes.filter(({ value }) => value !== 'nodata');
    }

    return selectableTypes;
  }, [sectionMangerScope]);

  const filteredTypes = useMemo(() => {
    if (sectionMangerScope) {
      return types.filter((type) => type !== 'nodata');
    }

    return types;
  }, [sectionMangerScope, types]);

  const [w1, w2, w3] = useWeeklyProductManagement(params);

  const handleUpdateStore = async (toUpdate: TStoresToUpdate) => {
    await queryClient.cancelQueries(['assortment-analysis', 'weekly-product-management']);
    const values = queryClient.getQueriesData(['assortment-analysis', 'weekly-product-management']);
    values.forEach(([key]) => {
      const params = key[2] as unknown as TWeeklyProductManagementParams;
      if (params.year !== w3.year || params.week !== w3.week) return;

      queryClient.setQueryData(key, (values: TWeeklyProductManagementList | undefined) => {
        return (
          values?.map((record) => {
            const storeToUpdate = toUpdate?.[record.product_code];
            if (!storeToUpdate) {
              return record;
            }
            return { ...record, stores: { ...record.stores, ...storeToUpdate } };
          }) ?? []
        );
      });
    });
  };

  const isAllLoading = w1.isLoading && w2.isLoading && w3.isLoading;
  // get list of shelves from available data - not all shelves
  const shelves = useMemo(() => {
    return [
      ...new Set(
        w3.data?.map((item) =>
          pipe(
            item.shelf,
            O.getOrElse(() => ''),
          ),
        ),
      ),
    ].filter((item) => item.length);
  }, [w3.data]);

  const gridTemplateColumns = useMemo(() => {
    return {
      base: `60px 80px 136px 220px 120px 80px repeat(3, 1fr)`,
      '2xl': `min(0.3fr, 1fr) repeat(2, 1fr) 2fr 150px 100px repeat(3, 1fr)`,
    };
  }, []);

  const getSelectedIds = useMemo(() => {
    return {
      store_ids: monitorScope ? ['ALL'] : userStoreIds,
    };
  }, [monitorScope, userStoreIds]);

  const data = useMemo(() => {
    const f = w3.data?.filter(
      ({ shelf: currentShelf, stores }) =>
        (!shelf || shelf === O.toUndefined(currentShelf)) &&
        (!sectionMangerScope ||
          Object.entries(stores)
            .map(
              ([store_id, value]) =>
                O.toUndefined(value) &&
                getSelectedIds.store_ids?.includes(store_id as unknown as TStoreId) &&
                filteredTypes.map((key) => ASSERT_STATES[key as localTStoreManagementValue](value)).some((v) => v),
            )
            .some((v) => v)) &&
        (filteredTypes.length === filteredSelectableTypes.length ||
          Object.entries(stores)
            .map(
              ([store_id, value]) =>
                getSelectedIds.store_ids?.includes(store_id as unknown as TStoreId) &&
                filteredTypes
                  .map((key) => {
                    return ASSERT_STATES[key as localTStoreManagementValue](value);
                  })
                  .some((v) => v),
            )
            .some((v) => v)),
    );
    return f ?? [];
  }, [filteredTypes, w3.data, shelf, filteredSelectableTypes.length, sectionMangerScope, getSelectedIds.store_ids]);

  const [[start, end], setWindow] = useState([0, PAGE_SIZE]);

  const renderableRows = useMemo(() => {
    return data.slice(start, Math.min(data.length, end));
  }, [start, end, data]);

  const handleOnScroll: React.UIEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const { clientHeight, scrollHeight, scrollTop } = event.currentTarget;
      const singleRowHeightEstimate = scrollHeight / (end - start);

      if (isTop(scrollTop, singleRowHeightEstimate * 3) && Boolean(start)) {
        setWindow(([start, end]) => [Math.max(start - JUMP, 0), Math.max(PAGE_SIZE, end - JUMP)]);
        return;
      }

      if (isBottom(clientHeight, scrollHeight, scrollTop, singleRowHeightEstimate * 4) && end !== data.length) {
        setWindow(([start, end]) => [
          Math.min(start + JUMP, data.length - PAGE_SIZE),
          Math.min(end + JUMP, data.length),
        ]);
      }
    },
    [data, start, end],
  );

  if (isAllLoading || (!w1 && !w2 && !w3)) {
    return (
      <Card>
        <CardTitleSection backButtonLink={PATHS.SETTINGS.ASSORTMENT_ANALYSIS.ROOT.toPath()}>
          <Skeleton width="30%" />
        </CardTitleSection>
        <Skeleton width="100%" minHeight="250px" />
      </Card>
    );
  }

  return (
    <CardErrorBoundary>
      <Card>
        <Stack h="100%" maxHeight={'100vh'}>
          <CardTitleSection as="h2" fontWeight="bold">
            {t('SETTINGS.ASSORTMENT_ANALYSIS.PAGE_TITLE')}
          </CardTitleSection>
          <EditStoreContext>
            <Grid templateColumns="100%" gap={1}>
              <Card mb={6}>
                <CardTitleSection
                  as="h2"
                  alignItems="end"
                  settings={
                    <CardTitleSectionSettings>
                      <Filters
                        shelves={shelves}
                        shelf={shelf}
                        setShelf={onChangeShelf}
                        week={week}
                        year={params.year}
                        setWeek={onChangeWeek}
                        handleUpdateStore={handleUpdateStore}
                        types={filteredTypes}
                        setTypes={onChangeTypes}
                        isSummaryStatisticsVisible={summaryVisible}
                        toggleSummaryStatistics={toggleSummaryStatistics}
                      />
                    </CardTitleSectionSettings>
                  }
                >
                  {t('SETTINGS.ASSORTMENT_ANALYSIS.WEEKLY_PRODUCT_LIST_MANAGEMENT_TITLE')}
                </CardTitleSection>

                {summaryVisible ? <SummaryStatistics w1={w1} w2={w2} w3={w3} /> : null}

                <Box
                  width="100%"
                  height={`calc(100vh - ${summaryVisible ? 420 : 350}px)`}
                  overflowY="auto"
                  onScroll={handleOnScroll}
                >
                  {w1.isLoading || !w1.dataMap ? (
                    <Card>
                      <CardTitleSection backButtonLink={PATHS.SETTINGS.ASSORTMENT_ANALYSIS.ROOT.toPath()}>
                        <Skeleton width="30%" />
                      </CardTitleSection>
                      <Skeleton width="100%" minHeight="250px" />
                    </Card>
                  ) : null}
                  <Grid
                    templateColumns={gridTemplateColumns}
                    minWidth="936px"
                    sx={{
                      // eslint-disable-next-line no-useless-computed-key
                      ['& > div']: {
                        padding: '0.5rem',
                        wordBreak: 'break-all',
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <GridItem>{t('SETTINGS.ASSORTMENT_ANALYSIS.PRODUCTS_LIST_TABLE.SHELF_LABEL')}</GridItem>
                    <GridItem>{t('SETTINGS.ASSORTMENT_ANALYSIS.PRODUCTS_LIST_TABLE.IFLS_LABEL')}</GridItem>
                    <GridItem>{t('SETTINGS.ASSORTMENT_ANALYSIS.PRODUCTS_LIST_TABLE.EAN_LABEL')}</GridItem>
                    <GridItem>{t('SETTINGS.ASSORTMENT_ANALYSIS.PRODUCTS_LIST_TABLE.NAME_LABEL')}</GridItem>
                    <GridItem>{t('SETTINGS.ASSORTMENT_ANALYSIS.PRODUCTS_LIST_TABLE.DELIVERY_DAYS_LABEL')}</GridItem>
                    <GridItem>{t('SETTINGS.ASSORTMENT_ANALYSIS.PRODUCTS_LIST_TABLE.PACKAGE_SIZE_LABEL')}</GridItem>
                    <GridItem fontWeight="bold">{`W${week - 2}`}</GridItem>
                    <GridItem fontWeight="bold">{`W${week - 1}`}</GridItem>
                    <GridItem fontWeight="bold">{`W${week}`}</GridItem>
                  </Grid>
                  <Grid
                    templateColumns={gridTemplateColumns}
                    minWidth="936px"
                    sx={{
                      // eslint-disable-next-line no-useless-computed-key
                      ['& > div']: {
                        padding: '0.5rem',
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    {!isAllLoading &&
                      renderableRows.map((item) => (
                        <React.Fragment key={item.product_code}>
                          <GridItem>{pipe(item.shelf, getOrEmptyString)}</GridItem>
                          <GridItem>{pipe(item.ifls, O.toNullable)?.toString().padStart(6, '0') || 'UNKNOWN'}</GridItem>
                          <GridItem>{item.product_code}</GridItem>
                          <GridItem>{item.description}</GridItem>
                          <GridItem>
                            <HStack gap={0}>
                              {item.delivery_days.map((daySelected, index) => {
                                return (
                                  <Stack gap={0} key={`delivery-days-${item.product_code}-${index}`}>
                                    <span>{daysLetters[index]}</span>
                                    <Checkbox isChecked={Boolean(daySelected)} isDisabled mt="0px !important" />
                                  </Stack>
                                );
                              })}
                            </HStack>
                          </GridItem>
                          <GridItem>{pipe(item.package_size, getOrZero)}</GridItem>
                          <ProductManagementColumn
                            productsList={w1}
                            item={item}
                            store={monitorScope ? 'ALL' : userStoreIds[0]}
                          />
                          <ProductManagementColumn
                            productsList={w2}
                            item={item}
                            store={monitorScope ? 'ALL' : userStoreIds[0]}
                          />
                          <ProductManagementColumn
                            productsList={w3}
                            item={item}
                            store={monitorScope ? 'ALL' : userStoreIds[0]}
                            editable={true}
                          />
                        </React.Fragment>
                      ))}
                  </Grid>
                </Box>
                <Footer year={params.year} week={params.week} />
              </Card>
            </Grid>
          </EditStoreContext>
        </Stack>
      </Card>
    </CardErrorBoundary>
  );
};
