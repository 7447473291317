import { Box, GridItem, Grid, Text, HStack, Tooltip } from '@chakra-ui/react';
import { LevelInput } from '../LevelInput';
import { useStocksReducer } from 'hooks/useStocksReducer';
import React, { useMemo, VFC } from 'react';
import { getOrFalse, getOrEmptyString, getOrZero } from '../../utils';
import { useUserStoreIds } from 'hooks';
import { STOCK_COMPRESSED_TEMPLATE_COLLUMNS } from '../../../consts/css';
import { StockInfoButtons, StockInfoCollapse, StockInfoCollapseProvider } from 'pages/MorningTasksPage/components';
import { AlertIcon, DiscountIcon, DailyIcon } from 'components/Icons';
import { useTranslation } from 'react-i18next';

type StockRowProps = {
  productCode: string;
};

const ROW_STYLES = {
  flexDirection: 'column',
  minW: '85px',
  justifyContent: 'center',
  gap: '1rem',
  display: 'flex',
};

export const CompressedStockRow: VFC<StockRowProps> = ({ productCode }) => {
  const { t } = useTranslation('whisperme');
  const userStores = useUserStoreIds();

  const [state] = useStocksReducer();

  const { description, available, stock_problems, ifls, shelf, insights, expectedToday, is_promo } = useMemo(() => {
    const { description, always_available, delivered, insights, stock_problems, ifls, shelf, is_promo } =
      state[productCode];

    return {
      description,
      ifls: getOrEmptyString(ifls),
      available: getOrFalse(always_available),
      shelf: getOrEmptyString(shelf),
      stock_problems: getOrFalse(stock_problems),
      expectedToday: getOrZero(delivered),
      insights: insights,
      is_promo: getOrFalse(is_promo),
    };
  }, [state, productCode]);

  return (
    <StockInfoCollapseProvider>
      <Box
        justifySelf="center"
        alignSelf="center"
        bg="white"
        borderRadius="base"
        w="100%"
        border="1px"
        borderColor="gray.200"
        boxShadow="base"
        paddingX={2}
        paddingY={1}
      >
        <Grid templateColumns={STOCK_COMPRESSED_TEMPLATE_COLLUMNS} gap={2}>
          <GridItem sx={ROW_STYLES} fontSize="sm">
            {ifls}
          </GridItem>
          <GridItem sx={ROW_STYLES} fontSize="sm">
            {productCode}
          </GridItem>
          <GridItem sx={ROW_STYLES} fontSize="sm">
            <HStack>
              <Text as="span">{description}</Text>
              {available ? (
                <Tooltip label={t('ANALYTICS.PRODUCTS.LIST_PAGE.ALWAYS_AVAILABLE_LABEL')} shouldWrapChildren>
                  <DailyIcon w="1.25em" h="1.25em" color="green.500" />
                </Tooltip>
              ) : null}
              {is_promo ? (
                <Tooltip label={t('ANALYTICS.PRODUCTS.LIST_PAGE.IS_PROMO_LABEL')} shouldWrapChildren>
                  <DiscountIcon w="1.25em" h="1.25em" color="orange.500" />
                </Tooltip>
              ) : null}
              {stock_problems ? (
                <Tooltip label={t('ANALYTICS.PRODUCTS.LIST_PAGE.STOCK_PROBLEMS_LABEL')} shouldWrapChildren>
                  <AlertIcon w="1.25em" h="1.25em" color="yellow.500" />
                </Tooltip>
              ) : null}
            </HStack>
          </GridItem>
          <GridItem sx={ROW_STYLES} fontSize="sm">
            {shelf}
          </GridItem>
          <GridItem sx={ROW_STYLES} fontSize="sm">
            <LevelInput type="start_of_day" productCode={productCode} size="sm" />
          </GridItem>
          <GridItem sx={ROW_STYLES} fontSize="sm">
            {expectedToday}
          </GridItem>
          <GridItem>
            <StockInfoButtons />
          </GridItem>
        </Grid>
        <StockInfoCollapse productCode={productCode} insights={insights} storeId={userStores[0]} />
      </Box>
    </StockInfoCollapseProvider>
  );
};
