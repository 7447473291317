import { Box, Center, Grid, GridItem, Stack, Text, FormControl, FormLabel, IconButton } from '@chakra-ui/react';
import { Card, CardTitleSection, CardTitleSectionSettings } from 'components/Card';
import { ChartLoadingOverlay } from 'components/Charts';
import { ShortagesSort } from 'components/Select';
import { GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import { subDays } from 'date-fns';
import { TStoreId } from 'domain/stores';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { AnimatePresence } from 'framer-motion';
import { useUserStoreIds } from 'hooks';
import { useStockInsightsObject, useShortageList } from 'hooks/data';
import { StockInfoButtons, StockInfoCollapse, StockInfoCollapseProvider } from 'pages/MorningTasksPage/components';
import { StockShortageListHeader } from 'pages/MorningTasksPage/StocksPage/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateToYMD } from 'utils/date';
import { DIMENSIONS } from './consts';
import { useFilterParams } from './hooks';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { ShortageProductsListParams } from 'domain/stocks';

const getOrEmpty = O.getOrElse(() => '');
const getOrZero = O.getOrElse(() => 0);

interface Props {
  storeId: TStoreId;
  yesterday: Date;
  lastDays: number;
  dataVisibility: boolean[];
}

export const DailyTaskShortageList: React.FC<Props> = ({ storeId, yesterday, lastDays, dataVisibility }) => {
  const { t } = useTranslation('whisperme');
  const { columnSort, order, onChangeColumnSort, onChangeOrder } = useFilterParams();
  const userStores = useUserStoreIds();

  const { data, isFetching } = useShortageList({
    store_id: storeId,
    start_date: dateToYMD(subDays(yesterday, lastDays - 1)),
    end_date: dateToYMD(yesterday),
    is_promo: false,
    always_available: dataVisibility[0] === dataVisibility[1] ? undefined : dataVisibility[1],
    column_sort: columnSort,
    order,
  });

  const { stockInsights, isFetching: isInsightsFetching } = useStockInsightsObject();

  if (!data) {
    return (
      <Center>
        <Text as="span">{t('COMPONENTS.NO_DATA_LABEL')}</Text>
      </Center>
    );
  }

  return (
    <Card>
      <CardTitleSection
        as="h3"
        settings={
          <CardTitleSectionSettings>
            <FormControl display="inline-flex" alignSelf="center" alignItems="center" flexDirection="row">
              <FormLabel fontSize="sm" mb="0" w="3rem">
                {t('ANALYTICS.DISCOUNTS_PAGE.FILTERS.SORT_BY_LABEL')}
              </FormLabel>
              <ShortagesSort<Required<ShortageProductsListParams>['column_sort']>
                onChange={onChangeColumnSort}
                value={columnSort}
              />
            </FormControl>
            <IconButton
              alignSelf="center"
              aria-label={`Order_${order ?? ''}}`}
              icon={order === 'DESC' ? <ArrowDownIcon /> : <ArrowUpIcon />}
              variant="outline"
              onClick={() => {
                onChangeOrder(order === 'ASC' ? 'DESC' : 'ASC');
              }}
              size="sm"
              mr={3}
            />
          </CardTitleSectionSettings>
        }
      >
        {t('DAILY_TASKS.SHORTAGE.SHORTAGE_PRODUCTS_LIST_LABEL')}
      </CardTitleSection>
      <Stack flexDirection="column" flex="1 1 auto" minWidth={DIMENSIONS.minWidth} position="relative">
        {
          <Box w="100%">
            <StockShortageListHeader />
            {data &&
              !isInsightsFetching &&
              data.map((entry) => (
                <StockInfoCollapseProvider>
                  <Box
                    justifySelf="center"
                    alignSelf="center"
                    mb={2}
                    bg="white"
                    borderRadius="base"
                    w="100%"
                    boxShadow="base"
                    paddingX={2}
                    paddingY={1}
                  >
                    <Stack>
                      <Grid templateColumns="0.75fr repeat(9, 1fr) 6rem" gap={GRID_GAP_DEFAULT_VALUE}>
                        <GridItem fontSize="sm">{pipe(entry.ifls, getOrEmpty)}</GridItem>
                        <GridItem fontSize="sm">{entry.product_code}</GridItem>
                        <GridItem fontSize="sm">{pipe(entry.shelf, getOrEmpty)}</GridItem>
                        <GridItem fontSize="sm">{entry.description}</GridItem>
                        <GridItem fontSize="sm">
                          {pipe(
                            entry.always_available,
                            O.getOrElse(() => false),
                            (always_available) =>
                              always_available ? t('COMPONENTS.YES_LABEL') : t('COMPONENTS.NO_LABEL'),
                          )}
                        </GridItem>
                        <GridItem fontSize="sm">{pipe(entry.shortage_hours, getOrZero)}</GridItem>
                        <GridItem fontSize="sm">{pipe(entry.shortage_days, getOrZero)}</GridItem>
                        <GridItem fontSize="sm">{pipe(entry.shortage_weeks, getOrZero)}</GridItem>
                        <GridItem fontSize="sm">{pipe(entry.sales, getOrZero)}</GridItem>
                        <GridItem fontSize="sm">{pipe(entry.last_order_date, getOrEmpty)}</GridItem>
                        <GridItem fontSize="sm">
                          <StockInfoButtons />
                        </GridItem>
                      </Grid>
                      <StockInfoCollapse
                        productCode={String(entry.product_code)}
                        insights={stockInsights[entry.product_code]}
                        storeId={userStores[0]}
                      />
                    </Stack>
                  </Box>
                </StockInfoCollapseProvider>
              ))}
          </Box>
        }
        <AnimatePresence>{isFetching && <ChartLoadingOverlay />}</AnimatePresence>
      </Stack>
    </Card>
  );
};
