import { Center, Grid, GridItem, Skeleton, Text } from '@chakra-ui/react';
import { Card, CardTitleSection } from 'components/Card';
import { GridTableHeader } from 'components/GridTableHeader';
import { GRID_GAP_DEFAULT_VALUE } from 'constants/css';
import * as PATHS from 'constants/paths';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { useUserStoreIds } from 'hooks';
import { useAssortmentAnalysisList } from 'hooks/data';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ASSORTMENT_ANALYSIS_TEMPLATE_COLUMNS } from './consts/css';

const getOrEmpty = O.getOrElse(() => '');

export const AssortmentAnalysisListPage: React.VFC = () => {
  const { t } = useTranslation('whisperme');
  const userStoreIds = useUserStoreIds();
  const { data: assortmentData, isFetching } = useAssortmentAnalysisList({ store_id: userStoreIds[0] });

  if (!assortmentData || isFetching) {
    return (
      <Card>
        <CardTitleSection backButtonLink={PATHS.SETTINGS.USERS.ROOT.toPath()}>
          <Skeleton width="30%" />
        </CardTitleSection>
        <Skeleton width="100%" minHeight="250px" />
      </Card>
    );
  }

  return (
    <Card mb={5}>
      <CardTitleSection as="h2">{t('SETTINGS.ASSORTMENT_ANALYSIS.LIST_PAGE_TITLE')}</CardTitleSection>

      {!isFetching && !assortmentData.length ? (
        <Card>
          <Center>
            <Text as="span">{t('COMPONENTS.NO_DATA_LABEL')}</Text>
          </Center>
        </Card>
      ) : null}

      {!isFetching && assortmentData.length ? (
        <GridTableHeader
          rowProps={{
            width: '100%',
            gap: GRID_GAP_DEFAULT_VALUE,
            templateColumns: ASSORTMENT_ANALYSIS_TEMPLATE_COLUMNS,
          }}
          items={[
            t('SETTINGS.ASSORTMENT_ANALYSIS.IFLS_LABEL'),
            t('SETTINGS.ASSORTMENT_ANALYSIS.ORDER_DESCRIPTION_LABEL'),
            t('SETTINGS.ASSORTMENT_ANALYSIS.AVAILABLE_IN_MERCURIEL_LABEL'),
            t('SETTINGS.ASSORTMENT_ANALYSIS.AVAILABLE_IN_BLI_LABEL'),
            t('SETTINGS.ASSORTMENT_ANALYSIS.AVAILABLE_IN_APP_LABEL'),
            t('SETTINGS.ASSORTMENT_ANALYSIS.IS_PROMO_LABEL'),
          ]}
        />
      ) : null}

      <Grid width="100%" templateColumns={ASSORTMENT_ANALYSIS_TEMPLATE_COLUMNS}>
        {assortmentData.map((item) => {
          return (
            <React.Fragment key={item.product_code}>
              <GridItem fontSize="sm" borderBottom="1px" borderColor="gray.200" py={3}>
                {pipe(item.ifls, getOrEmpty)}
              </GridItem>
              <GridItem fontSize="sm" borderBottom="1px" borderColor="gray.200" py={3}>
                {pipe(item.order_description, getOrEmpty)}
              </GridItem>
              <GridItem fontSize="sm" borderBottom="1px" borderColor="gray.200" py={3}>
                {pipe(
                  item.mercuriel_data,
                  O.getOrElse(() => false),
                  (mercuriel_data) => (mercuriel_data ? t('COMPONENTS.YES_LABEL') : t('COMPONENTS.NO_LABEL')),
                )}
              </GridItem>
              <GridItem fontSize="sm" borderBottom="1px" borderColor="gray.200" py={3}>
                {pipe(
                  item.bli_data,
                  O.getOrElse(() => false),
                  (bli_data) => (bli_data ? t('COMPONENTS.YES_LABEL') : t('COMPONENTS.NO_LABEL')),
                )}
              </GridItem>
              <GridItem fontSize="sm" borderBottom="1px" borderColor="gray.200" py={3}>
                {pipe(
                  item.stock_data,
                  O.getOrElse(() => false),
                  (stock_data) => (stock_data ? t('COMPONENTS.YES_LABEL') : t('COMPONENTS.NO_LABEL')),
                )}
              </GridItem>
              <GridItem fontSize="sm" borderBottom="1px" borderColor="gray.200" py={3}>
                {pipe(
                  item.is_promo,
                  O.getOrElse(() => false),
                  (is_promo) => (is_promo ? t('COMPONENTS.YES_LABEL') : t('COMPONENTS.NO_LABEL')),
                )}
              </GridItem>
            </React.Fragment>
          );
        })}
      </Grid>
    </Card>
  );
};
